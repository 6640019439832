'use client'
import { LoadingContext } from '@/context/tools/loading'
import { useBrands } from '@/hooks/brands'
import { types } from '@/types'
import { handleKeyRandom, sorter } from '@/utils/functions'
import { BrandLink } from '@web/_components/cards/BrandLink'
import { memo, useContext } from 'react'

const BrandsLinks = props => {
  'use memo'
  const { brands } = useBrands(props)
  const { modules } = useContext(LoadingContext)

  return sorter(brands, types.sorter.sequence).map((x, i) => i < 15 && <BrandLink key={handleKeyRandom(`brand-link`, i)} {...{
    logo: x.logo,
    text: x.webdata,
    sequence: x.sequence,
    hostname: props.hostname,
    colors: props.colors,
    modules
  }} />)
}
export default memo(BrandsLinks)
