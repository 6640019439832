import CarouselImg from './CarouselImg'

const CarouselIndicators = ({
  elements, activeIndex, show, handleMouseEnter, handleMouseLeave, thumb, imageIndicators, setActiveIndex, domain, styles
}) => {
  return (show || imageIndicators) && elements.length > 1 &&
  <div
    className={`${show && `absolute bottom-0 left-0 right-0 z-[2] list-none`} flex p-0 overflow-x-scroll overflow-y-hidden`}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    {elements.map((img, index) => imageIndicators ?
      <div
        key={index}
        className={`rounded-xl ${activeIndex === index ? `contrast-75` : `contrast-125`} m-1 cursor-pointer size-[60px] flex items-center justify-center`}
        onMouseOver={ () => setActiveIndex(index) }
        onClick={ () => setActiveIndex(index) } >
        <CarouselImg {...{ img, thumb, domain }}
          className={`${styles?.cardBorder || `image-rounded`} object-contain h-full min-w-12`}
          width={70}
          height={70}
          alt={img?.alt || `slide_${index + 1}`}
          title={img?.title || `slide_${index + 1}`}
        />
      </div> :
      <span
        key={index}
        className={`mx-[3px] box-content h-1 w-8 flex-initial rounded-lg bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none ${activeIndex === index ? `bg-neutral-600` : `bg-black`}`}
        aria-label={`Slide ${index + 1}`}/>)}
  </div>
}

export default CarouselIndicators
