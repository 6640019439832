'use client'
import { useView } from "@/utils/functions/view/viewHelper"
import parse from "html-react-parser"
import ScrollButtons from "./button/ScrollButtons"
import ChildrenWrapper from "./subcomponents/ChildrenWrapper"

export const TWCarousel = ({ ...props }) => {
  const {
    mobile,
    text = ``,
    number = 0,
    position = ``,
    type = `products`,
    ischild,
    hostname
  } = props
  const { ref, view } = useView(hostname)
  return (
    <div ref={ref} id={`${type}_carousel_${number ?? 0}_${position}`} className={`justify-center ${!ischild ? `my-5` : ``}`}>
      {
        view && <>
          {!!text.length && <div id={`carousel_${type}_head_${number ?? 0}_${position}`} className="w-full items-end pb-2 flex">
            <h2 className="mt-1">
              {parse(text || ``)}
            </h2>
            {!mobile && <ScrollButtons {...props} {...{ type }}/>}
          </div>}
          <ChildrenWrapper {...props} {...{ type }} >
            {props.children}
          </ChildrenWrapper>
        </>
      }
    </div>
  )
}
