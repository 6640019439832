'use client'
import React from 'react'
import { Buttons } from './Buttons'

const ScrollButtons = ({
  viewbuttons = false, posslot, number, position, type, ...props
}) => {
  // a function to scroll right or left
  const handleScrollClick = prop => {
    const dragRef = document.getElementById(`carousel_${type}_scrollable_${number ?? 0}_${position}`)
    dragRef.scrollLeft += parseInt(prop, 10)
  }
  return viewbuttons && (
    <div className="flex flex-row ml-auto">
      <Buttons dir={true} onClickSlot={() => handleScrollClick(`-${posslot}`)} {... props } />
      <Buttons dir={false} onClickSlot={() => handleScrollClick(posslot)} {... props } />
    </div>
  )
}

export default ScrollButtons
