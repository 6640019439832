'use client'
import CarouselWrapper from '@/components/tailwind/CarouselWrapper'
import { useCarouselInterval } from '@/hooks/carousel/useCarouselInterval'
import { useMediaQuerys } from '@/hooks/utils/useMediaQuerys'
import { memo, Suspense, useCallback, useRef, useState } from 'react'
import CarouselArrows from './subcomponents/CarouselArrows'
import CarouselImg from './subcomponents/CarouselImg'
import CarouselIndicators from './subcomponents/CarouselIndicators'
import LinkWrapper from './subcomponents/LinkWrapper'
export const experimental_ppr = true
const TOUCH_THRESHOLD = 50

// Moved outside component to avoid recreation
const getImageHeight = (
  product, mobile, _1380, _1680, width, height
) => {
  if (product) {
    return `auto`
  }
  if (!mobile) {
    return undefined
  }
  if (_1680) {
    return 650
  }
  if (_1380) {
    return 500
  }
  return 300
}

const ImagesCarousel = memo(({
  images,
  hostname,
  setOpenImgModal,
  indicators = false,
  arrows = true,
  imageIndicators = false,
  product = false,
  modal = false,
  width,
  height,
  ...props
}) => {
  const { business, mobile, domain, styles } = hostname
  const { thumb } = business
  const { _1380, _1680 } = useMediaQuerys()
  const [ activeIndex, setActiveIndex ] = useState(0)
  const touchRef = useRef({ startX: 0, endX: 0 })
  const totalSlides = images?.length
  const shouldAutoPlay = !mobile && !product && !modal

  const { startInterval, clearExistingInterval } = useCarouselInterval(totalSlides, shouldAutoPlay, setActiveIndex)

  const handleNext = useCallback(() => {
    setActiveIndex(prevIndex => (prevIndex + 1) % totalSlides)
  }, [totalSlides])

  const handlePrev = useCallback(() => {
    setActiveIndex(prevIndex => (prevIndex - 1 + totalSlides) % totalSlides)
  }, [totalSlides])

  // Combined touch handlers into one
  const touchHandlers = {
    onTouchStart: useCallback(e => {
      if (mobile) {
        touchRef.current.startX = e.touches[0].clientX
      }
    }, [mobile]),
    onTouchMove: useCallback(e => {
      if (mobile) {
        touchRef.current.endX = e.touches[0].clientX
      }
    }, [mobile]),
    onTouchEnd: useCallback(e => {
      if (mobile) {
        const { startX, endX } = touchRef.current
        const diff = startX - endX
        if (endX !== 0 && Math.abs(diff) > TOUCH_THRESHOLD) {
          diff > 0 ? handleNext() : handlePrev()
        }
        touchRef.current.endX = 0
      }
    }, [ mobile, handleNext, handlePrev ])
  }

  // Memoized handlers
  const mouseHandlers = {
    onMouseEnter: useCallback(() => clearExistingInterval(), [clearExistingInterval]),
    onMouseLeave: useCallback(() => startInterval(), [startInterval])
  }

  const imageHeight = getImageHeight(
    product, mobile, _1380, _1680
  )
  const imageClassName = `object-center ${modal ? `object-contain max-h-full` : product ? `object-contain max-h-[55dvh]` : mobile && `object-cover h-full`}`
  return (
    <Suspense fallback={null}>
      <CarouselWrapper
        {...mouseHandlers}
        {...touchHandlers}
        className="justify-self-center"
        {...props}
      >
        <Suspense fallback={null}>
          <div className="relative flex items-center w-full overflow-hidden after:clear-both after:block after:content-[''] mb-2">
            {images?.map((img, index) => (
              <div
                key={index}
                style={{ height: imageHeight }}
                className={`relative flex justify-center float-left -mr-[100%] w-full transition-all duration-[800ms] ease-in-out motion-reduce:transition-none 
              ${activeIndex === index ? `opacity-100` : `opacity-0`} ${modal ? `h-[60dvh] 1200:h-[80vh]` : `h-full`}`}>
                <LinkWrapper url={images.find((_, i) => i === activeIndex)?.url}>
                  <CarouselImg
                    img={img}
                    thumb={thumb}
                    domain={domain}
                    product={product}
                    style={{ height: imageHeight }}
                    className={imageClassName}
                    onClick={() => setOpenImgModal && setOpenImgModal(true)}
                    alt={img?.alt || `image_${index + 1}`}
                    title={img?.title || `image_${index + 1}`}
                  />
                </LinkWrapper>
              </div>
            ))}
            {images.length > 1 && (
              <CarouselArrows
                handlePrev={handlePrev}
                handleNext={handleNext}
                show={arrows}
                {...mouseHandlers}
              />
            )}
          </div>
        </Suspense>
        <CarouselIndicators
          elements={images}
          activeIndex={activeIndex}
          show={indicators}
          domain={domain}
          thumb={thumb}
          imageIndicators={imageIndicators}
          setActiveIndex={setActiveIndex}
          {...{ mouseHandlers, styles }}
        />
      </CarouselWrapper>
    </Suspense>
  )
})

export default ImagesCarousel
