' use client'
import { handleKeyRandom } from "@/utils/functions"
import React, { memo } from "react"
import { ProductCard } from "../card"
import { TWCarousel } from "../carousel"

const Relations = ({ ...props }) => {
  const {
    hostname, carouselTitle = ``, prodList, origin, small
  } = props
  const { mobile } = hostname
  return prodList.length > 0 && (
    <TWCarousel {...props} {...{
      text: carouselTitle, mobile, posslot: 200, height: 500
    }}>
      {prodList.map((elem, i) => (
        <ProductCard
          key={handleKeyRandom(`prod_card_${origin}`, i)}
          {...{
            mobile, small, domain: hostname?.domain, element: elem, origin, hostname, events: hostname?.events, discount: elem.discount, product: elem
          }}
        />
      ))}
    </TWCarousel>
  )
}

export default memo(Relations)
