'use client'
import { useEffect, useState } from "react"

export const useProductImage = ({ image, domain, thumb }) => {
  const [ imgUrl, setImgUrl ] = useState(image)
  const [ ratio, setRatio ] = useState(`contain`)

  useEffect(() => {
    let url = ``
    if (image !== null) {
      if (image?.includes(process.env.NEXT_PUBLIC_IMG_CHECK)) {
        url = image
      } else if (!thumb?.endsWith(`.`)) {
        url = `${thumb}${image}`
      } else {
        url = `${thumb}${domain === `us` ? `com` : domain}${image}`
      }
    }
    return setImgUrl(url)
  }, [ image, thumb ])

  return [ imgUrl, ratio ]
}
