const { useRef, useCallback, useEffect } = require(`react`)

const INTERVAL_DELAY = 5000

export const useCarouselInterval = (totalSlides, shouldAutoPlay, setActiveIndex) => {
  const intervalIdRef = useRef(null)

  const clearExistingInterval = useCallback(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = null
    }
  }, [])

  const startInterval = useCallback(() => {
    if (shouldAutoPlay && totalSlides > 1) {
      clearExistingInterval()
      intervalIdRef.current = setInterval(() => {
        setActiveIndex(prevIndex => (prevIndex + 1) % totalSlides)
      }, INTERVAL_DELAY)
    }
  }, [ shouldAutoPlay, totalSlides, setActiveIndex ])

  useEffect(() => {
    startInterval()
    return clearExistingInterval
  }, [ startInterval, clearExistingInterval ])

  return { startInterval, clearExistingInterval }
}
