'use client'
import { CartContext } from "@/context"
import { ToolsContext } from "@/context/tools/tools"
import { getDateDiff } from "@/utils/functions"
import { getImageUrl } from "@/utils/functions/images/imagesHelper"
import { useContext, useMemo } from "react"
import CardImage from "./component/CardImage"
import { Labels } from "./Labels"

export const BikeCardContent = props => {
  const { element, hostname, small } = props
  const { image, releaseDate, imgalt, title, kms, year, isProf, reserved } = element
  const price = element?.price ?? element?.cost
  const { business, mobile, domain, colors } = hostname
  const urlThumb = getImageUrl(image, business?.thumb, domain)
  const cardWidth = mobile || small ? 299 : 350
  const imgHeight = mobile || small ? 250 : 300
  const { cart: { currency }} = useContext(CartContext)
  const { tools: { texts }} = useContext(ToolsContext)
  const bikeTexts = useMemo(() => texts.bike_page, [texts])

  return (
    <div style={{ overflow: `unset`, width: cardWidth }}
      className={`hover:border-neutral-200 p-2 border-transparent border rounded-2xl m-1 contrastCard relative`}>
      <div style={{ overflow: `unset`, height: imgHeight, width: `100%` }}
        className={`rounded-2xl flex items-center justify-center contrastParent relative`}>
        {getDateDiff(releaseDate) < 7 && <Labels {...props} bike={true}/>}
        <CardImage {...{ urlThumb, imgalt, hostname, cardWidth, bike: true }} />
      </div>
      <div className={`px-3 py-1`}>
        <p style={{ color: colors.color }} className={`font-semibold flex m-0 ${small ? `h-8 line-clamp-1` : `h-10 line-clamp-2`} ${small ? `text-xs` : ``}`}>
          {title}
        </p>
        <p style={{ color: colors.darkGrey }} className={`text-xs flex line-clamp-2`}>
          {year}
        </p>
        <span style={{ display: `flex`, justifyContent: `space-between`, alignItems: `end` }}>
          <p style={{ color: colors.darkGrey }} className={`text-xs flex line-clamp-2 whitespace-nowrap`}>
            {kms}{` Kms`}
          </p>
          <p style={{ color: colors.darkGrey }} className={`text-xs flex line-clamp-2`}>
            {!!bikeTexts && reserved ? bikeTexts.reserved : ``}
          </p>
        </span>
        <span style={{ display: `flex`, justifyContent: `space-between`, alignItems: `end`, height: 25 }}>
          <p style={{ color: colors.secondary }} className="font-semibold line-clamp-1">
            {!!bikeTexts && `${price}${currency?.symbol}`}
          </p>
          <p style={{ color: isProf ? colors.color : colors.secondary }} className="font-semibold line-clamp-1">
            {!!bikeTexts && `${bikeTexts.seller} ${isProf ? bikeTexts.proSeller : bikeTexts.privSeller}`}
          </p>
        </span>
      </div>
    </div>
  )
}
