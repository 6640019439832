import { forwardRef, memo } from 'react'

const CarouselWrapper = forwardRef(({
  children, className = ``, style, colors, minh = 350, maxh = 650, ...props
}, ref) => {
  return (
    <div {...props} ref={ref}
      className={`${className} carousel-wrapper transition-all`}
      onClick={e => e.stopPropagation()}
      style={{
        ...style,
        "--minh": minh, '--maxh': maxh
      }}>
      {children}
    </div>
  )
})
export default memo(CarouselWrapper)
