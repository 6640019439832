'use client'

import { apiCallElk } from "@/middleware/api"
import { useState } from "react"
import { useEffectOnce } from "../utils/useEffectOnce"

export const useBrands = props => {
  const { hostname, bike } = props
  const { business } = hostname
  const [ brands, setBrands ] = useState([])
  const handleBrands = async prop => {
    const obj = {
      ...prop,
      abbrElastic: business.abbrElastic
    }
    const { code, result } = await apiCallElk({
      url: `/brands`,
      type: `POST`,
      encrypt: obj
    })
    await setBrands(x => result)
  }
  const handleBikeBrands = async prop => {
    const obj = {
      ...prop,
      abbrElastic: business.abbrElastic
    }
    const { code, result } = await apiCallElk({
      url: `/brands`,
      type: `POST`,
      encrypt: obj
    })
    await setBrands(result)
  }

  useEffectOnce(() => {
    bike ? brands?.length === 0 && handleBikeBrands({
      platformId: hostname?.id,
      languageId: hostname?.langId
    }) :
      brands.length === 0 && handleBrands({
        platformId: hostname?.id,
        languageId: hostname?.langId,
        size: 15
      })
  })

  return {
    brands
  }
}
