import { useProductImage } from "@/hooks/product/useProductImage"
import Image from "next/image"
import Link from "next/link"

export const BrandLink = ({
  logo, text, hostname, colors, sequence, modules, ...props
}) => {
  const { domain, host } = hostname
  const [urlThumb] = useProductImage({
    image: logo?.image,
    domain,
    thumb: hostname?.business?.thumb
  })
  return (
    <li className="mx-1">
      <Link prefetch={false} draggable={false} id={`brand_${sequence}`} href={`${host}/${text.url}`} passHref scroll={false} onClick={() => modules.handleLinkLoading(true)}>
        <div className={`bg-[${colors.white}] rounded-2xl m-1 cursor-pointer w-28 h-28 block transition-transform duration-300 hover:scale-110`}
          style={{ boxShadow: `0px 0px 0px 2px ${colors.lightGrey}` }}>
          <Image
            src={urlThumb}
            draggable={false}
            alt={text.title}
            width={150}
            height={150}
            loading="lazy"
            decoding="async"
            className="rounded-2xl w-28 h-28 object-cover"
            unoptimized={true}
          />
        </div>
      </Link>
    </li>
  )
}
