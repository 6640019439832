'use client'
import Relations from '../products/Relations'
import HtmlLanding from './HtmlLanding'
import { useView } from '@/utils/functions/view/viewHelper'

export const Landing = props => {
  const {
    hostname, arr, position
  } = props

  const { ref, view } = useView(hostname)

  return (
    <div ref={ref}>
      {view && arr.map((x, i) => !x.obj?.carousel ?
        <HtmlLanding key={`landing-section-block${i}`} {...{ html: x.obj.block }} /> :
        <Relations
          key={`landing-section-block${i}`}
          {...{
            hostname,
            carouselTitle: x.obj.block?.translations ? x.obj.block.translations[hostname?.lang.toLowerCase()] : ``,
            prodList: x.relations,
            origin: `other`,
            viewbuttons: true,
            number: i,
            position
          }}
        />)}
    </div>
  )
}
