'use client'
import { cleanPath } from '@/utils/functions'
import '@web/_components/card/style.css'
import Link from 'next/link'
import Card from './component/Card'
import { Suspense, useContext } from 'react'
import { LoadingContext } from '@/context/tools/loading'
import { ToolsContext } from '@/context/tools/tools'

export const ProductCard = props => {
  const { element, origin, hostname } = props

  const { url } = element
  const { host, styles } = hostname
  const { modules } = useContext(LoadingContext)

  const { tools: { colors }} = useContext(ToolsContext)
  return (
    <>
      <Link prefetch={false} draggable="false"
        href={{
          pathname: host + cleanPath(url),
          query: { rel: origin }
        }}

        // as={host + cleanPath(url)}
        style={{ textDecoration: `none` }}onClick={() => modules.handleLinkLoading(true)}
        className={`${styles?.cardBorder ? styles.cardBorder : `card-rounded`}`}
        passHref
        scroll={true}>
        <Suspense >{<Card {...props} {...{ colors }}/>}</Suspense>
      </Link>
    </>
  )
}
