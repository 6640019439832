'use client'
import styled from "@emotion/styled"

export const SVGImage = styled(`div`)(({
  size = `20px`, fillcolor = `black`, wdth = null, hght = null
}) => ({
  padding: `5px`,
  "& svg, & img": {
    width: wdth ?? size,
    height: hght ?? size,
    color: `${fillcolor} !important`,
    "& path": {
      fill: `${fillcolor} !important`,
    },
  },
}))
