import { DropDownArrows } from '@/app/(web)/_components/basket/subcomponent/components/subcomponents/DropDownArrows'
import { TWButton } from '@/components/tailwind/Button'
import { handleKeyRandom } from "@/utils/functions"
import {
  ClickAwayListener, Grow,
  Popper
} from "@mui/material"
import parse from "html-react-parser"
import {
  useEffect, useId, useRef, useState
} from "react"
import {
  IoMdClose
} from "react-icons/io"
import validator from "validator"

export const MetaMoto = ({
  items, itemFilter, text = ``, handleClickItem, model, disabled, category, ...props
}) => {
  const { colors } = props
  const buttonRef = useRef()
  const idRef = useId()
  const [ open, setOpen ] = useState(false)
  const [ textSearch, setTextSearch ] = useState(``)
  const [ options, setOptions ] = useState(items)

  const handleMenuItemClick = async option => {
    await setOpen(false)
    await handleClickItem(model, option)
  }
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleMenuItemClear = async() => {
    await handleToggle()
    await handleClickItem(model, null)
  }
  const handleClose = event => {
    if (buttonRef.current && buttonRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }
  const handleTextSearch = (e, value) => {
    e.preventDefault()
    setTextSearch(e.target.value)
  }
  useEffect(() => {
    !validator.isEmpty(textSearch) ? setOptions(items.filter(x => x.name.toString().toUpperCase().
      includes(textSearch.toUpperCase()))) : setOptions(items)
  }, [textSearch])
  useEffect(() => {
    items?.length > 0 && setOptions(items)
  }, [items])

  return (
    <>
      <TWButton
        {...{ colors }}
        border={!disabled && text && colors.GreenAv}
        className={open ? `shadow` : ``}
        type="dropdown"
        ref={buttonRef}
        disabled={disabled}
        aria-controls={`split-button-menu-${itemFilter}`}
        aria-expanded={open ? `true` : `false`}
        aria-label="button-moto-search"
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        <p className={`w-40 line-clamp-1 m-0 ${disabled ? `text-neutral-200` : `inherit`}`}>
          {text ? parse(String(text)) : parse(itemFilter)}
        </p>
        { text ?
          <IoMdClose aria-label="handle-item-clear" onClick={() => handleMenuItemClear()} className="text-neutral-400 hover:text-black w-4 h-4"/> :
          <DropDownArrows {...{ open }} className={`${disabled ? `text-neutral-200` : `text-neutral-500`}`} />
        }
      </TWButton>
      <Popper open={open} anchorEl={buttonRef.current} transition={true}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === `bottom` ? `center top` : `center bottom`,
            }}
          >
            <div style={{ color: colors.color }} className="z-50 mt-2 bg-white rounded-md p-1 text-sm cursor-pointer shadow shadow-neutral-300 ">
              <ClickAwayListener onClickAway={handleClose}>
                <div className="flex flex-col">
                  <input
                    type="text"
                    className="w-full p-2 flex outline-none border-b-2 border-neutral-200"
                    id={`input-filter-${itemFilter}`}
                    onChange={handleTextSearch}
                    autoFocus={open}
                    value={textSearch} />
                  <div id={idRef} className="max-h-52 max-w-52 overflow-x-hidden overflow-y-auto py-1 ">
                    {options?.map((x, i) => (
                      <p
                        className={`hover:bg-neutral-100 m-0 py-1 px-2 text-wrap border-b-2 border-neutral-50`}
                        key={handleKeyRandom(idRef, i)}
                        onKeyDown={e => e.key === `Enter` && handleMenuItemClick(x.id)}
                        onClick={() => handleMenuItemClick(x.id)}
                      >
                        {parse(String(x.name))}&nbsp;{x.cc ? x.cc : ``}
                      </p>
                    ))}
                  </div>
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default MetaMoto
