import { getImageUrl } from '@/utils/functions/images/imagesHelper'
import { constants } from '@/utils/global'
import Image from 'next/image'
import React, { useState } from 'react'

const { NO_IMG } = constants

const CarouselImg = ({ className, img, thumb, domain, width, height, product, ...props }) => {
  const [ imgError, setImgError ] = useState(false)
  return (
    <Image {...props}
      className={`${className} ${imgError ? `w-auto` : `w-full`}`}
      style={{ width: imgError && product && `100px`, height: imgError && product && `100px` }}
      src={imgError ? NO_IMG : getImageUrl(img.image, thumb, domain)}
      width={ imgError && product ? 100 : width ?? product ? 640 : 1920}
      height={ imgError && product ? 100 : height ?? product ? 640 : 1080}
      draggable="false"
      priority={true}
      fetchPriority="high"

      // loading="lazy"
      decoding="async"
      onError={() => setImgError(true)}
      unoptimized={true}
    />
  )
}

export default CarouselImg
