'use client'
import styled from "@emotion/styled"

export const Htmlspan = styled(`span`)(({ colors, fontWeight }) => ({
  '& a': {
    color: `${colors.secondary} !important`,
    textDecoration: `none`,
    fontWeight: fontWeight ?? `normal`,
    '&:hover': {
      color: `${colors.tild} !important`,
    },
  },
  '& b': {
    fontWeight: `bold`,
  }
}))

export const Htmldiv = styled(`div`)(({ font, theme, colors }) => ({
  "& p": {
    marginTop: 6,
    marginBottom: font ? `inherit` : 12,
    height: `auto`,
    fontSize: theme.typography.pxToRem(font),
    color: colors.color,
    textDecoration: `none`,
    fontWeight: `inherit`,
  },
  "& a": {
    fontSize: theme.typography.pxToRem(font),
    textDecoration: `none`,
    color: colors.secondary,
    fontWeight: 600,
  },
}))
