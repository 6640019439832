'use client'
import parse from "html-react-parser"
import { Htmlspan } from "./style"
import { Suspense, useContext, useMemo } from "react"
import { ToolsContext } from "@/context/tools/tools"

export const Footer = props => {
  const { hostname } = props
  const { mobile } = hostname

  const { tools: { colors, texts }} = useContext(ToolsContext)
  const home_page = useMemo(() => texts?.home_page, [texts])
  return <Suspense fallback={null}>
    {
      !!home_page && (
        <div id="home-footer-texts" className="text-justify mt-2" >
          <div id="home-footer-title" className={`text-black px-2 text-center font-bold text-lg tablet:text-2xl`} >
            {parse(home_page.footer_home_title ?? ``)}
          </div>
          <div id="home-footer-description" className={`grid grid-cols-1 tablet:grid-cols-2`}>
            <div className="p-2 tablet:p-4 grid grid-cols-1">
              {home_page?.footer_home?.left.map((teleftTxt, i) => <Htmlspan {...{ colors }} key={`text-left-${i}`} className={`text-black justify-self-center font-normal text-md mt-1 mb-2 tablet:mb-3 ${mobile ? `max-w-80` : `max-w-full h-auto `}`} >
                {parse(teleftTxt)}
              </Htmlspan>)}
            </div>
            <div className="p-2 tablet:p-4 grid grid-cols-1">
              {home_page?.footer_home?.right.map((rightText, i) => <Htmlspan {...{ colors }} key={`text-right-${i}`} className={`text-black justify-self-center font-normal text-md tablet:mt-1 mb-3 h-auto ${mobile ? `max-w-80` : `max-w-full `} `} >
                {parse(rightText)}
              </Htmlspan>)}
            </div>
          </div>
        </div>
      )
    }
  </Suspense>
}

