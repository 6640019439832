'use client'
import { TWButton } from '@/components/tailwind/Button'
import { ToolsContext } from '@/context/tools/tools'
import { useContext } from "react"
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5"

export const Buttons = props => {
  const { dir = false, onClickSlot } = props
  const { tools: { colors }} = useContext(ToolsContext)
  return (
    <TWButton {...{ colors }} onClick={onClickSlot} className="m-1 p-0 w-8 h-8" bg={colors.color} hvr={colors.darkGrey2} >
      {dir ? <IoArrowBackSharp className="w-4 text-white" /> : <IoArrowForwardSharp className="w-4 text-white" />}
    </TWButton>
  )
}
