'use client'
import { useEffect, useRef } from "react"

const InfiniteCarousel = ({ children, ...props }) => {
  const brandsRef = useRef(null)
  useEffect(() => {
    const ul = brandsRef.current
    if (ul) {
      ul.insertAdjacentHTML(`afterend`, ul.outerHTML)
      ul.nextSibling.setAttribute(`aria-hidden`, `true`)
    }
  }, [])

  return (
    <div {...props } className="p-3 flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]" >
      <ul ref={brandsRef} className="flex items-center justify-center md:justify-start [&_img]:max-w-none animate-infinite-scroll list-none m-0 p-0">
        {children}
      </ul>
      <ul className="flex items-center justify-center md:justify-start [&_img]:max-w-none animate-infinite-scroll list-none m-0 p-0">
        {children}
      </ul>
    </div>
  )
}

export default InfiniteCarousel
