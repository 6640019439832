import { MenuContext } from "@/context"
import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import { constants } from "@/utils/global"
import { useContext, useState } from "react"
const {
  URL: { METAMOTO },
} = constants

export const useSearchMotos = () => {
  const [ brand, setBrand ] = useState([])
  const [ model, setModel ] = useState([])
  const [ year, setYear ] = useState([])
  const [ brandId, setBrandId ] = useState(null)
  const [ modelId, setModelId ] = useState(null)
  const [ yearId, setYearId ] = useState(null)
  const [ path, setPath ] = useState({ pathname: process.env.NEXT_PUBLIC_HOME })
  const { level1 } = useContext(MenuContext)

  const createPath = async(prop, router) => {
    if (modelId !== null && yearId !== null) {
      const tempBrand = await brand?.find(x => x.id === brandId)
      const tempModel = await model?.find(x => x.id === modelId)
      const tempYear = await year?.find(x => x.id === yearId)
      const catSearch = await level1.find(x => x.info.id === 234)

      const pathname = `${catSearch.path}${tempBrand.path.replace(`/`, ``)}search-${tempModel?.name.toLowerCase()}-${tempModel?.cc}-${tempYear?.name}/`
      if (prop) {
        await router.push(pathname)
      } else {
        await setPath({ pathname })
      }
    }
  }
  const getBrand = async props => {
    const sendData = {
      url: `${METAMOTO}/${props}`,
      encrypt: ``,
      type: `get`
    }
    const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
    const result = await apiCall(encriptedData)
    await setBrand(result.message)
  }

  const getModel = async(props, id) => {
    if (props) {
      const sendData = {
        url: `${METAMOTO}/${id}/${brandId}`,
        encrypt: ``,
        type: `GET`
      }
      const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
      const result = await apiCall(encriptedData)
      await setModel(result.message)
    } else {
      await setModel([])
      await setModelId(null)
    }
  }

  const getYear = async(props, id) => {
    if (props) {
      const sendData = {
        url: `${METAMOTO}/${id}/${brandId}/${modelId}`,
        encrypt: ``,
        type: `GET`
      }
      const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
      const result = await apiCall(encriptedData)
      await setYear(result.message)
    } else {
      await setYear([])
      await setYearId(null)

      // await setPath({ pathname: process.env.NEXT_PUBLIC_HOME })
    }
  }

  return {
    path,
    createPath,
    brand,
    model,
    year,
    getBrand,
    getModel,
    getYear,
    brandId,
    setBrandId,
    modelId,
    setModelId,
    yearId,
    setYearId,
  }
}

