'use client'
import { TWButton } from '@/components/tailwind/Button'
import { ToolsContext } from '@/context/tools/tools'
import { useView } from '@/utils/functions/view/viewHelper'
import InfiniteCarousel from "@web/_components/carousel/InfiniteCarousel"
import parse from "html-react-parser"
import Link from "next/link"
import { useContext, useMemo } from 'react'
import BrandsLinks from "./BrandsLinks"

export const Brands = props => {
  const {
    hostname, homeSections
  } = props
  const homeBrands = homeSections?.find(x => x.name === `homeBrands`)

  const { tools: { texts, colors }} = useContext(ToolsContext)
  const home_page = useMemo(() => texts?.home_page, [texts])
  const { ref, view } = useView(hostname)

  return !!home_page && !!homeBrands.show && <span ref={ref} id="brands" className="mb-5">
    {view && <>
      <div className={`mx-auto p-0 tablet:w-3/4`}>
        <p className={`text-center font-bold text-lg tablet:text-2xl`}>
          {parse(home_page.brands.title)}
        </p>
        <p className={`text-center font-medium text-base`}>
          {parse(home_page.brands.subtitle)}
        </p>
      </div>
      <InfiniteCarousel id="infinite_brands_carousel" >
        <BrandsLinks {...{
          hostname,
          colors
        }} />
      </InfiniteCarousel>
      <div className="flex justify-center my-2 mx-0">
        <Link href={hostname.host + home_page.brands.href} passHref scroll={true} style={{ textDecoration: `none` }}>
          <TWButton {...{ colors }} type="outlined" animated>
            {home_page.brands.button}
          </TWButton>
        </Link>
      </div>
    </>}
  </span>
}
