import { constants } from '@/utils/global'
import { forwardRef } from 'react'

const ChildrenWrapper = forwardRef(({
  number,
  position,
  type,
  mobile,
  width = constants.DRAWER,
  ...props
}, ref) => {
  return <div ref={ref} id={`carousel_${type}_scrollable_${number ?? 0}_${position}`}
    className={`flex self-baseline justify-start flex-nowrap overflow-x-auto overflow-y-hidden`}>
    {props.children}
  </div>
})

export default ChildrenWrapper
