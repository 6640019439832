import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const CarouselArrows = ({
  handlePrev, handleNext, handleMouseEnter, handleMouseLeave, show
}) => {
  return show && <>
    <div
      className="absolute h-full top-1/2 left-0 transform -translate-y-1/2 w-10 cursor-pointer flex p-2 items-center bg-black opacity-60 bg-opacity-0 hover:bg-opacity-10 hover:opacity-100 hover:text-black"
      onClick={handlePrev}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <FaChevronLeft size={20}/>
    </div>

    <div
      className="absolute h-full top-1/2 right-0 transform -translate-y-1/2 w-10 cursor-pointer flex p-2 items-center justify-end bg-black opacity-60 bg-opacity-0 hover:bg-opacity-10 hover:opacity-100 hover:text-black"
      onClick={handleNext}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <FaChevronRight size={20}/>
    </div>
  </>
}

export default CarouselArrows
